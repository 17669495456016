import React, { useState } from 'react';
import Layout from '../../component/layout/Layout';
import './ContactPage.css';

const ContactPage = () => {
    const [formData, setFormData] = useState({
        fname: '',
        email: '',
        phone: '',
        company: '',
        country: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        let updatedValue = value;

        // Validation for phone number
        if (name === 'phone') {
            // Allow only numbers and dashes
            updatedValue = value.replace(/[^\d-]/g, '');
        }

        setFormData({
            ...formData,
            [name]: updatedValue
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        alert('Wait a Second!')
        
        // Basic email format validation using regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            alert('Please enter a valid email address.');
            return;
        }
        
        try {
            const response = await fetch('https://rmcpcsecondform.onrender.com/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                alert('Message sent successfully');
                setFormData({
                    fname: '',
                    email: '',
                    phone: '',
                    company: '',
                    country: '',
                    subject: '',
                    message: ''
                });
            } else {
                alert('Error sending email');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error sending email');
        }
    };

    return (
        <Layout>
            <div className="background-section-contact">
                <div className="overlayAbout">
                    <h1>Get In Touch</h1>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh', textAlign: 'center' }}>
                <div className="container text-center">
                    <div className="container contact-page py-5 px-lg-0 text-center">
                        <div className="row text-center" style={{ textAlign: 'center', width: '100%', paddingLeft: '10%' }}>
                            <div className="col-md-12 contact-form wow fadeIn text-center" data-wow-delay="0.1s">
                                <h3 className="mb-5" id='HeadingContact' style={{ fontSize:'2.5rem' }}>Contact Us</h3>
                            </div>
                            <div className="bg-light p-4 text-center">
                                <form onSubmit={handleSubmit}>
                                    <div className="row g-3">
                                        <div className="col-md-12">
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    name="fname"
                                                    className="form-control"
                                                    id="name"
                                                    placeholder="Your Name"
                                                    value={formData.fname}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="name" style={{ fontWeight: 'bold' }}>Your Name</label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-floating">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className="form-control"
                                                    id="email"
                                                    placeholder="Your Email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="email" style={{ fontWeight: 'bold' }}>Your Email</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    className="form-control"
                                                    id="phone"
                                                    placeholder="Phone Number"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="phone" style={{ fontWeight: 'bold' }}>Phone Number</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    name="company"
                                                    className="form-control"
                                                    id="company"
                                                    placeholder="Company Name"
                                                    value={formData.company}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="company" style={{ fontWeight: 'bold' }}>Company Name</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    name="country"
                                                    className="form-control"
                                                    id="country"
                                                    placeholder="Country"
                                                    value={formData.country}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="country" style={{ fontWeight: 'bold' }}>Country</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    name="subject"
                                                    className="form-control"
                                                    id="subject"
                                                    placeholder="Subject"
                                                    value={formData.subject}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="subject" style={{ fontWeight: 'bold' }}>Subject</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <textarea
                                                    className="form-control"
                                                    name="message"
                                                    placeholder="Leave a message here"
                                                    id="message"
                                                    style={{ height: '100px' }}
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                    required
                                                ></textarea>
                                                <label htmlFor="message" style={{ fontWeight: 'bold' }}>Message</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="w-100 py-3" type="submit" name="btnsubmit" id='SubmitBtn'>Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ContactPage;
