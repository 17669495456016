import React from 'react'
import Layout from '../../component/layout/Layout'
// import Contact from '../../component/contact/Contact'
import Hero from '../../component/hero/Hero'
// import About from '../../component/about/About'
import NewContact from '../../component/newcontact/NewContact'
// import Product from '../../component/product/Product'
// import Directors from '../../component/director/Directors'
import NewAbout from '../../component/newabout/NewAbout'
import Aspirin from '../../component/aspirin/Aspirin'
import Whyus from '../../component/whyus/Whyus'

const HomePage = () => {
  return (
    <Layout>
      <Hero/>
      <NewAbout/>
      {/* <About/> */}
      {/* <Directors/> */}
      {/* <Contact/> */}
      <Aspirin/>
      <Whyus/>
      {/* <Product/> */}
      <NewContact/>
    </Layout>
  )
}

export default HomePage
