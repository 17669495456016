import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Hero.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import DOMPurify from 'dompurify';
import 'animate.css';


const Hero = () => {
  useEffect(() => {
    AOS.init({ duration: 1000});
  }, []);

  const slides = [
    {
      image: '/img/bg1.jpg',
      subtitle: 'Get the perfect chemicals.',
      title: 'Manufacture & Exporter of Bulk Drugs ',
      description: 'Innovating Aspirin Production with Precision and Excellence for Global Solutions.',
    },
    {
      image: '/img/bg2.jpg',
      subtitle: 'Get the perfect chemicals.',
      title: 'Manufacture & Exporter of Bulk Drugs ',
      description: 'Innovating Aspirin Production with Precision and Excellence for Global Solutions.',
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="slide">
            <div
              className="slide-background"
              style={{ backgroundImage: `url(${slide.image})` }}
            >
              <div className="slide-content justify-content-start">
                <h5 className="text-white text-uppercase mb-3 animated slideInDown">{slide.subtitle}</h5>
                <h1 className="display-3 text-white animated animate__backInRight" data-aos="fade-left" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(slide.title) }}/>
                <p className="fw-medium text-white mb-4 pb-2">{slide.description}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Hero;
