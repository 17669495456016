import React, { useEffect } from 'react';
import AOS from 'aos';
import "./About.css"
import 'aos/dist/aos.css';

const About = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <section id="why-us" className="why-us section-bg">
            <div className="container-fluid" data-aos="fade-up">
                <div className="row">
                    <div
                        className="col-lg-5 align-items-stretch video-box"
                        data-aos="zoom-in"
                        data-aos-delay="100"
                    >
                        <img
                            src="/img/about.jpg" // Replace with your image path
                            alt="Your Image"
                            className="overlay-image"
                        />
                    </div>

                    <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch">
                        <div className="content">
                            <h3>
                                About Us
                            </h3>
                            <h4>Welcome To RMC Performance Chemicals</h4>
                            <p></p>
                        </div>

                        <div className="accordion-list">
                            <ul>
                                <li>
                                    <p>
                                    RMC Performance Chemicals Pvt. Ltd., located at E-3/Part, Additional Jalgaon Industrial Area, MIDC Jalgaon – 425003, was incorporated on 18/10/2019 (CIN: U24299MH2019PTC331828). A subsidiary of Siddharth Carbochem Products Ltd (SCPL), India's largest manufacturer of Methyl Salicylate & Salicylic Acid derivatives, RMC proposes a world-class Aspirin manufacturing facility at their existing MIDC Jalgaon site.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About
