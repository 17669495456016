// src/NewAbout.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'aos/dist/aos.css';
import AOS from 'aos';
import './NewAbout.css';

const NewAbout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const redirect = () => {
    navigate('/about');
  };

  const redirect1 = () => {
    navigate('/boardofdirector');
  };

  const redirect2 = () => {
    navigate('/contact');
  };

  const scrollToContact = () => {
    const contactSection = document.getElementById('homeContactContainer');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="App" data-aos="zoom-in">
      <div className="overlay"></div>
      <div className="about-us">
        <div className="about-text">
          <h2>About Us</h2>
          <p>
            RMC Performance Chemicals Pvt. Ltd., located at E-3/Part, Additional Jalgaon Industrial Area, MIDC Jalgaon – 425003, was incorporated on 18/10/2019 (CIN: U24299MH2019PTC331828). A subsidiary of Siddharth Carbochem Products Ltd (SCPL), India's largest manufacturer of Methyl Salicylate & Salicylic Acid derivatives, RMC proposes a world-class Aspirin manufacturing facility at their existing MIDC Jalgaon site.
          </p>
        </div>
        <div className="Buttons">
          <button onClick={redirect}  className="Btn-footer company-btn">The Company</button>
          <button onClick={redirect1} className="Btn-footer team-btn">Our Team</button>
          <button onClick={scrollToContact} className="Btn-footer locations-btn">Locations</button>
        </div>
        <div className="footer-buttons">
          <button className="Btn-footer iso-btn">Our Certificates</button>
          <button onClick={redirect} className="Btn-footer learn-btn">About Us</button>
          <button onClick={redirect2} className="Btn-footer quote-btn">Request a Quote</button>
        </div>
      </div>
    </div>
  );
};

export default NewAbout;
