import React from 'react'
import Layout from '../../../component/layout/Layout'
import Director from '../../../component/director/Directors'
import './Board.css'


const BoardOfDirector = () => {
  return (
    <Layout>
       <div className="background-section-board">
        <div className="overlayAbout">
          <h1>Board Of Directors</h1>
          {/* <p>This is a responsive background section with text overlay.</p> */}
        </div>
      </div>
      <Director/>
    </Layout>
  )
}

export default BoardOfDirector
