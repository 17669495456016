import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import './Aspirin.css'

const Aspirin = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    return (
        <div className="material-innovation-wrapper"  data-aos="fade-right">
            <div className="image-container">
                <img src='/img/aspirin 1500 x 1500.jpg' alt="Material Innovation" data-aos="fade-up" />
            </div>
            <div>
                <div className="material-innovation-container">
                    <div className="text-section">
                        <h1>Our Product</h1>
                        <h2>Aspirin / Acetyl Salicylic Acid</h2>
                        <p>
                            Aspirin, or acetylsalicylic acid, is a common medication used to reduce pain, fever, and inflammation. It works by inhibiting enzymes that produce prostaglandins, which cause these symptoms. Additionally, aspirin helps prevent blood clots, reducing the risk of heart attacks and strokes, but it can cause gastrointestinal irritation and bleeding
                        </p>
                        <a href="/aspirinpage" class="innovation-button">View Product</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Aspirin
