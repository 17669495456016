import React from 'react'
import { Link } from 'react-router-dom';
import './Footer.css'

const Footer = () => {
  return (
    <div>
      <div className="container-fluid bg-dark text-light footer pt-5 wow fadeIn" data-wow-delay="0.1s" style={{ marginTop: '6rem' }}>
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h4 className="mb-4" id='Links'>Contact Us</h4>
              {/* <p className="mb-2">
              <i className="fa fa-phone-alt me-3"></i>UK<br />
              Tel.: +44 (208) 819 4264<br />
              Fax.: +44 (203) 695 2580
            </p> */}
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3"></i>
                Tel.: +91- (022) 694710385<br />
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope me-3"></i> admin@rmcpc.com
              </p>
              <div className="d-flex pt-2">
                <a className="btn btn-outline-light btn-social" href="/">
                  <i className="fab fa-twitter"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="/">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="/">
                  <i className="fab fa-youtube"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="/">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="mb-4" id='Links'>Quick Links</h4>
              <Link className="btn btn-link" to="/about">About Us</Link>
              <Link className="btn btn-link" to="/contact">Contact Us</Link>
              {/* <Link className="btn btn-link" to="/service">Our Services</Link> */}
              <Link className="btn btn-link" to="/privacypolicy">Privacy and Policy</Link>
              <Link className="btn btn-link" to="/disclaimer">Disclaimer</Link>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="mb-4" id='Links'>Products</h4>
              <Link className="btn btn-link" to="/aspirinpage">Aspirin / Acetyl Salicylic Acid</Link>
            </div>

            <div className="col-lg-3 col-md-6">
              <h4 className="mb-4"  id='Links'>Address</h4>
              <p className="mb-2" id='footerad'>
                <i className="fa fa-map-marker-alt me-3"></i>Jalgaon Office<br />
                 E-3/ Part Additional Jalgaon Industrial Area MIDC Jalgaon–425003<br />
              </p>
              <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3"></i>Head Office<br />
              World Trade Center Mumbai, Center 1, Unit Number 3A, 6th floor, Cuffe Parade, Colaba, Mumbai - 400005.<br />
              </p>
              <div className="position-relative mx-auto" style={{ maxWidth: '400px' }}>
              </div>
            </div>
          </div>
        </div>
       
      </div>
      <div className="copyright">
          © 2020 RMC Performance Chemicals. All Rights Reserved.
        </div>
    </div>
  )
}

export default Footer
