import React from 'react'
import Layout from '../../component/layout/Layout'
import "./AboutPage.css"
import About from '../../component/about/About'

const AboutPage = () => {
  return (
    <Layout>
      <div className="background-section">
        <div className="overlayAbout">
          <h1>About Us</h1>
          {/* <p>This is a responsive background section with text overlay.</p> */}
        </div>
      </div>
      <About/>
    </Layout>
  )
}

export default AboutPage
