import React from 'react'
import Layout from '../../component/layout/Layout'
import './Disclaimer.css'

const Disclaimer = () => {
    return (
        <Layout>
            <div className="background-section-disclaimer">
                <div className="overlaydisclaimer">
                    <h1>Disclaimer</h1>
                    <p>The Disclaimer that is presented by RMCPL is to protect our products and its uses and the company We take no resposibility beyond the points mentioned in this policy.</p>
                </div>
            </div>
            <div className="container ">
                <div className="row align-items-center py-5">
                    <p>Visitors to this web site are bound by the following terms and conditions (“terms”) so please read these carefully before continuing to use this site. If you do not agree with any of these terms, please do not use this site. You also acknowledge and agree that we may modify this Agreement at any time, in our sole discretion; that all modifications to this Agreement will be effective immediately upon our posting of the modifications on this site; and that you will review this Agreement each time you access this site, so that you are aware of and agree to any and all modifications made to this Agreement. You also acknowledge and agree that, unless we specifically provide otherwise, this Agreement only applies to this site and our online activities, and does not apply to any of our offline activities.
                    </p>
                    <h3 style={{ color: '#087eca' }}>Disclaimer of Warranties, Inaccuracies or Errors</h3>
                    <p>1. Although Rishabh Metals & Chemicals Pvt. Ltd. tries to ensure that all information and recommendations, whether in relation to the products, services, offerings or otherwise (hereinafter “Information”) provided as part of this website is correct at the time of inclusion on the web site, Rishabh Metals & Chemicals Pvt. Ltd. does not guarantee the accuracy of the Information. Rishabh Metals & Chemicals Pvt. Ltd. makes no representations or warranties as to the completeness or accuracy of Information.</p>
                    <p>2. Rishabh Metals & Chemicals Pvt. Ltd. makes no commitment to update or correct any Information that appears on the Internet or on this web site.</p>
                    <p>3. Information is supplied upon the condition that the persons receiving the same will make their own determination as to its suitability for their purposes prior to use or in connection with the making of any decision. No Information at this web site shall constitute an invitation to invest in Rishabh Metals & Chemicals Pvt. Ltd. or any affiliates. Any use of this website or the Information is at your own risk. Neither Rishabh Metals & Chemicals Pvt. Ltd. and Rishabh Metals & Chemicals Pvt. Ltd. affiliates, nor their officers, employees or agents shall be liable for any loss, damage or expense arising out of any access to, use of, or reliance upon, this website or the Information, or any website linked to this website.</p>
                    <p>4. Nothing contained herein is to be construed as a recommendation to use any product, process, equipment or formulation, in conflict with any patent, or otherwise and Rishabh Metals & Chemicals Pvt. Ltd. makes no representation or warranty, express or implied that, the use thereof will not infringe any patent, or otherwise.</p>
                    <p>5. Rishabh Metals & Chemicals Pvt. Ltd. and its affiliates and associates shall not be liable, at any time, for any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communications line failure, theft or destruction or unauthorized access to, alteration of, or use of information contained at this site.</p>
                    <h3 style={{ color: '#087eca' }}>Rishabh Metals & Chemicals Pvt. Ltd. Disclaimer</h3>
                    <p>This website is provided to you on an “as is” and “where-is” basis, without any warranty. Reliance Energy Ltd., for itself and any third party providing materials, services, or content to this website, makes no representations or warranties, either express, implied, statutory or otherwise of merchantability, fitness for a particular purpose, or non-infringement of third party rights, with respect to the website, the information or any products or services to which the information refers. Rishabh Metals & Chemicals Pvt. Ltd. will not be liable to you or any third party for any damages of any kind, including but not limited to, direct, indirect, incidental, consequential or punitive damages, arising from or connected with the site, including but not limited to, your use of this site or your inability to use the site, even if Rishabh Metals & Chemicals Pvt. Ltd. has previously been advised of the possibility of such damages.</p>
                    <p>Rishabh Metals & Chemicals Pvt. Ltd. companies have their own separate identities but in this web site we may sometimes use “Reliance Energy Ltd.”, “Group”, “we” or “us” when we refer to Rishabh Metals & Chemicals Pvt. Ltd. companies in general or where no useful purpose is served by identifying any particular Rishabh Metals & Chemicals Pvt. Ltd. company.</p>
                    <h3 style={{ color: '#087eca' }}>Availability</h3>
                    <p>1. Rishabh Metals & Chemicals Pvt. Ltd. controls and operates this web site from India and makes no representation that the materials are appropriate or will be available for use in other locations. If you use this web site from outside the India, you are entirely responsible for compliance with all applicable local laws.</p>
                    <p>2. Rishabh Metals & Chemicals Pvt. Ltd. does not make any warranty or representation that a user in one region may obtain the Services from the Rishabh Metals & Chemicals Pvt. Ltd. site in another region and Rishabh Metals & Chemicals Pvt. Ltd. may cancel a user’s order or redirect a user to the site for that user’s region if a user attempts to order Services offered on a site in another region.</p>
                    <p>3. Information that Rishabh Metals & Chemicals Pvt. Ltd. publishes on the World Wide Web may contain references or cross references to Rishabh Metals & Chemicals Pvt. Ltd. products, programs and services that are not announced or available in your country. Such references do not imply that Rishabh Metals & Chemicals Pvt. Ltd. intends to announce such products, programs or services in your country. Consult your local Rishabh Metals & Chemicals Pvt. Ltd. business contact for information regarding the products, programs and services that may be available to you.</p>
                    <h3 style={{ color: '#087eca' }}>Third Party Interaction and Links to Third Party Sites</h3>
                    <p>In your use of the web site, you may enter into correspondence with, purchase goods and/or services from, or participate in promotions of advertisers or members or sponsors of the web site. Unless otherwise stated, any such correspondence, advertisement, purchase or promotion, including the delivery of and the payment for goods and/or services, and any other term, condition, warranty or representation associated with such correspondence, purchase or promotion, is solely between you and the applicable third party. You agree that Rishabh Metals & Chemicals Pvt. Ltd. has no liability, obligation or responsibility for any such correspondence, purchase or promotion between you and any such third party.</p>
                    <p>This website may be linked to other websites on the World Wide Web that are not under the control of or maintained by Reliance Energy Ltd.. Such links do not indicate any responsibility or endorsement on our part for the external website concerned, its contents or the links displayed on it. These links are provided only as a convenience, in order to help you find relevant websites, services and/or products that may be of interest to you, quickly and easily. It is your responsibility to decide whether any services and/or products available through any of these websites are suitable for your purposes. Rishabh Metals & Chemicals Pvt. Ltd. is not responsible for the owners or operators of these websites or for any goods or services they supply or for the content of their websites and does not give or enter into any conditions, warranties or other terms or representations in relation to any of these or accept any liability in relation to any of these (including any liability arising out of any claim that the content of any external web site to which this web site includes a link infringes the intellectual property rights of any third party).</p>
                    <h3 style={{ color: '#087eca' }}>Copyrights</h3>
                    <p>This web site contains material, including text, images, graphics, video and sound, which is protected by copyright and/or other intellectual property rights. All copyright and other intellectual property rights in this material are either owned by Rishabh Metals & Chemicals Pvt. Ltd. or have been licensed to Rishabh Metals & Chemicals Pvt. Ltd. by the owner(s) of those rights so that it can use this material as part of this web site. Rishabh Metals & Chemicals Pvt. Ltd. retains copyright on all Information, including text, graphics and sound and all trademarks displayed on this web site are owned by Rishabh Metals & Chemicals Pvt. Ltd. and used under licence by Rishabh Metals & Chemicals Pvt. Ltd. affiliates.</p>
                    <h3 style={{ color: '#087eca' }}>You May</h3>
                    <p>•use and display the materials only on your personal computer only for personal use. Rishabh Metals & Chemicals Pvt. Ltd. grants you a limited, personal, non-exclusive and non-transferable license only for such use. •print copies of the information on this site for your personal use and store the files on your computer for personal use. 3.You may</p>
                    <h3 style={{ color: '#087eca' }}>not</h3>
                    <p>•copy (whether by printing off onto paper, storing on disk, downloading or in any other way), distribute (including distributing copies), download, display, perform, reproduce, distribute, modify, edit, alter, enhance, broadcast or tamper with in any way or otherwise use any material contained in the web site except as set out under “You may”. These restrictions apply in relation to all or part of the material on the web site;
                        •copy and distribute this information on any other server, or modify or re-use text or graphics on this system or another system. No reproduction of any part of the web site may be sold or distributed for commercial gain nor shall it be modified or incorporated in any other work, publication or web site, whether in hard copy or electronic format, including postings to any other web site.
                        •remove any copyright, trade mark or other intellectual property notices contained in the original material from any material copied or printed off from the web site; link to this web site; without our express written consent.</p>

                    <h3 style={{ color: '#087eca' }}>General Terms and Conditions</h3>
                    <p>Rishabh Metals & Chemicals Pvt. Ltd. does not routinely monitor your postings to the web site but reserves the right to do so. However, in our efforts to promote good citizenship within the Internet community, if Rishabh Metals & Chemicals Pvt. Ltd. becomes aware of inappropriate use of the web site or any of its Services, Rishabh Metals & Chemicals Pvt. Ltd. will respond in any way that, in its sole discretion, Rishabh Metals & Chemicals Pvt. Ltd. deems appropriate. You acknowledge that Rishabh Metals & Chemicals Pvt. Ltd. will have the right to report to law enforcement authorities any actions that may be considered illegal, as well as any information it receives of such illegal conduct. When requested, Rishabh Metals & Chemicals Pvt. Ltd. will co-operate fully with law enforcement agencies in any investigation of alleged illegal activity on the Internet.
                        Submissions and unauthorised use of any materials contained on this web site may violate copyright laws, trademark laws, the laws of privacy and publicity, certain communications statutes and regulations and other applicable laws and regulations. You alone are responsible for your actions or the actions of any person using your user name and/or password. As such, you shall indemnify and hold Rishabh Metals & Chemicals Pvt. Ltd. and its officers, directors, employees, affiliates, agents, licensors, and business partners harmless from and against any and all loss, costs, damages, liabilities, and expenses (including attorneys’ fees) incurred in relation to, arising from, or for the purpose of avoiding, any claim or demand from a third party that your use of the web site or the use of the web site by any person using your user name and/or password (including without limitation your participation in the posting areas or your Submissions) violates any applicable law or regulation, or the rights of any third party.
                        Rishabh Metals & Chemicals Pvt. Ltd. reserves the right to terminate access to this web site at any time and without notice. Further this limited license terminates automatically, without notice to you, if you breach any of these Terms. Upon termination, you must immediately destroy any downloaded and printed materials.
                        Rishabh Metals & Chemicals Pvt. Ltd. may change the terms and conditions and disclaimer set out above from time to time. By browsing this web site you are accepting that you are bound by the current terms and conditions and disclaimer and so you should check these each time you revisit the web site.
                        Rishabh Metals & Chemicals Pvt. Ltd. may change the format and content of this web site at any time.
                        Rishabh Metals & Chemicals Pvt. Ltd. may suspend the operation of this web site for support or maintenance work, in order to update the content or for any other reason.
                        Personal details provided to Rishabh Metals & Chemicals Pvt. Ltd. through this web site will only be used in accordance with our privacy policy. Please read this carefully before going on. By providing your personal details to us you are consenting to its use in accordance with our privacy policy.
                        Rishabh Metals & Chemicals Pvt. Ltd. reserves all other rights.</p>
                    <h3 style={{ color: '#087eca' }}>Limitation of Liability</h3>
                    <p>Rishabh Metals & Chemicals Pvt. Ltd. shall have absolutely no liability in connection with the site material including any liability for damage to your computer hardware, data, information, materials, or business resulting from the information or the lack of information available on the Rishabh Metals & Chemicals Pvt. Ltd. website. in no event shall Rishabh Metals & Chemicals Pvt. Ltd. be liable for any for any special, incidental, consequential, exemplary or punitive damages for any loss, arising out of or in connection with (a) the use, misuse or the inability to use this site; (b) unauthorized access to or alteration of your transmissions or data; (c) statements or conduct of any third party on the site; or (e) any other matter relating to this website.</p>
                    <h3 style={{ color: '#087eca' }}>Applicable Law and Jurisdiction</h3>
                    <p>These terms and conditions are governed by and to be interpreted in accordance with laws of India, without regard to the choice or conflicts of law provisions of any jurisdiction. You agree, in the event of any dispute arising in relation to these terms and conditions or any dispute arising in relation to the web site whether in contract or tort or otherwise, to submit to the jurisdiction of the courts located at Mumbai, India for the resolution of all such disputes.</p>

                </div>
            </div>
        </Layout>
    )
}

export default Disclaimer
