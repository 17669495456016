import React, {useEffect} from 'react'
import Layout from '../../component/layout/Layout'
import './Product.css'
import {Link} from "react-router-dom"
import 'aos/dist/aos.css';
import AOS from 'aos';


const bulkDrugsData = [
    {
      title: 'Aspirin / Acetyl Salicylic Acid',
      image: '/img/aspirin 1500 x 1500.jpg', // Update with the correct path
      link: '/aspirinpage'
    }
  ];

const ProductPage = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    return (
        <Layout>
            <div className="background-section-product">
                <div className="overlayproduct">
                    <h1>Products and Services</h1>
                    {/* <p>Our Privacy Policy contains the information to our rights and our client’s information through this site. Under no circumstances shall our client’s infomation will be compromised.</p> */}
                </div>
            </div>
            <section id="why-us" className="why-us">
                <div className="container-fluid" data-aos="fade-up">
                    <section className="featured-product section-padding">
                        <div className="container">
                            <div className="row">
                                <div className="text-center">
                                    <h1 className='productHeading' style={{ textTransform: 'capitalize' }}>Our Products</h1>
                                    <br />
                                </div>
                                {bulkDrugsData.map((drug, index) => (
                                    <div key={index} className="col-lg-4 col-12 mb-3">
                                        <div className="product-thumb">
                                            <img
                                                src={drug.image}
                                                style={{ backgroundColor: '#ffffff' }}
                                                className="img-fluid product-image"
                                                alt={drug.title}
                                            />
                                            <div className="product-info">
                                                <div>
                                                    <h5 className="product-title mb-0">
                                                        <Link to={drug.link} className="product-title-link">{drug.title}</Link>
                                                    </h5>
                                                    <br />
                                                    <Link to={drug.link} className="get-started-btn">View Products</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </Layout>
    )
}

export default ProductPage
