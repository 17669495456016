import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const location = useLocation();
  const [navbarMobile, setNavbarMobile] = useState(false);
  const [dropdownActive, setDropdownActive] = useState({});

  const handleDropdownClick = (dropdownId) => {
    setDropdownActive((prevState) => ({
      ...prevState,
      [dropdownId]: !prevState[dropdownId],
    }));
  };

  const toggleMobileNav = () => {
    setNavbarMobile(!navbarMobile);
  };

  return (
    <header id="header" className={`fixed-top ${navbarMobile ? 'navbar-mobile' : ''}`}>
      <div className="container d-flex align-items-center justify-content-between">
        <h1 className="logo">
          <Link to="/">
            <img src="/newlogo.png" alt="Logo" />
          </Link>
        </h1>
        <nav id="navbar" className={`navbar ${navbarMobile ? 'navbar-mobile' : ''}`}>
          <ul>
            <li>
              <Link className={`nav-link scrollto ${location.pathname === '/' ? 'active' : ''}`} to="/">
                Home
              </Link>
            </li>
            <li className={`dropdown ${dropdownActive['about'] ? 'dropdown-active' : ''}`}>
              <Link
                className={`nav-link scrollto ${dropdownActive['about'] ? 'active' : ''}`}
                to="/about"
                onClick={() => handleDropdownClick('about')}
              >
                About Us
              </Link>
              <ul className={`${dropdownActive['about'] ? 'dropdown-active' : ''}`}>
                <li>
                  <Link to="/about">Certificates</Link>
                </li>
                <li>
                  <Link to="/boardofdirector">Board of Director</Link>
                </li>
              </ul>
            </li>
            <li className={`dropdown ${dropdownActive['products'] ? 'dropdown-active' : ''}`}>
              <Link
                className={`nav-link scrollto ${dropdownActive['products'] ? 'active' : ''}`}
                to="/products"
                onClick={() => handleDropdownClick('products')}
              >
                Products
              </Link>
              <ul className={`${dropdownActive['products'] ? 'dropdown-active' : ''}`}>
                <li>
                  <Link to="/aspirinpage">Aspirin / Acetyl Salicylic Acid</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link className={`nav-link scrollto ${location.pathname === '/newsmedia' ? 'active' : ''}`} to="/">
                News & Media
              </Link>
            </li>
            <li>
              <Link className="getstarted scrollto" to="/contact" id="contact">
                Contact Us
              </Link>
            </li>
          </ul>
          <i
            className={`fa ${navbarMobile ? 'fa-times' : 'fa-bars'} mobile-nav-toggle`}
            aria-label="Toggle navigation"
            onClick={toggleMobileNav}
          ></i>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
