import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom"

import HomePage from './pages/homepage/HomePage'
import AboutPage from './pages/aboutpage/AboutPage'
import BoardOfDirector from './pages/aboutpage/boardofdirector/BoardOfDirector'
import ContactPage from './pages/contactpage/ContactPage'
import PrivacyPolicy from './pages/privacypolicy/PrivacyPolicy'
import Disclaimer from './pages/disclaimer/Disclaimer'
import ProductPage from './pages/productpage/ProductPage'
import AspirinPage from './pages/productpage/products/aspirinpage/AspirinPage'




const App = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/boardofdirector' element={<BoardOfDirector />} />
        <Route path='/products' element={<ProductPage />} />
        <Route path='/contact' element={<ContactPage />} />

        <Route path='/privacypolicy' element={<PrivacyPolicy />} />
        <Route path='/disclaimer' element={<Disclaimer />} />

        {/* sperate page */}

        <Route path='/aspirinpage' element={<AspirinPage />} />


      </Routes>
    </Router>
  )
}

export default App
