import React from 'react'
import "./Director.css"

const Card = ({ name, platform, description }) => (
    <figure className="snip1533">
        <figcaption>
            <blockquote>
                <p>{description}</p>
            </blockquote>
            <h3>{name}</h3>
            <h4>{platform}</h4>
        </figcaption>
    </figure>
);



const Directors = () => {
    return (
        <div>
            <div className="card-container">

                <Card name="Mr. Ashesh Ramesh Jain" platform="Director" description="The directors lead strategic decision-making and governance to align with organizational goals and stakeholder interests." />
                <Card name="Mrs. Riddhi Rishabh Jain" platform="Director" description="The directors lead strategic decision-making and governance to align with organizational goals and stakeholder interests." />
                {/* Add more <Card /> components as needed */}
            </div>
        </div>
    )
}

export default Directors
