import React from 'react';
import "./Topline.css";

const Topline = () => {

  

  return (
    <div className="topline">
      <div className="social-icons">
        {/* Place your social media icons here */}
        <a href="/"><i className="fab fa-facebook"></i></a>
        <a href="/"><i className="fab fa-linkedin"></i></a>
        <a href="/"><i className="fab fa-youtube"></i></a>
        {/* Add more social icons as needed */}
      </div>
      <div className="contact-info">
        <span className="email"><i className="far fa-envelope"></i> admin@rmcpc.com</span>
        <span className="phone"><i className="fas fa-phone"></i> +91- (022) 694710385</span>
      </div>
    </div>
  );
};

export default Topline;
