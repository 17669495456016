import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'aos/dist/aos.css';
import AOS from 'aos';
import "./Whyus.css"

const Whyus = () => {

    const direction = useNavigate();

      useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    const redirectabout = ()=>{
        direction('/about');
    }

    const redirectcontact = ()=>{
        direction('/contact');
    }

    return (
        <div>
            <div className='parent-container'>
                <div className='whychooseheading'>
                    <h2 className='why-heading '>Why Choose RMCPC</h2>
                </div>
            </div>
            <div className="why-choose-textech">

                <div className="features">
                    <div className="feature" data-aos="fade-up">
                        <div className="icon"><img src="/img/5year.svg" alt="" /></div>
                        <p>Over Decades of Invaluable Experience</p>
                    </div>
                    <div className="feature" data-aos="fade-down">
                        <div className="icon"><img src="/img/high-performance.svg" alt="" /></div>
                        <p>High Standards of Product Quality</p>
                    </div>
                    <div className="feature" data-aos="fade-up">
                        <div className="icon"><img src="/img/iso-certified-1.svg" alt="" /></div>
                        <p>Global Service and Product delivery</p>
                    </div>
                    <div className="feature" data-aos="fade-down">
                        <div className="icon"><img src="/img/solutions.svg" alt="" /></div>
                        <p>Solving Global Problems using Chemistry</p>
                    </div>
                </div>
                <div className="buttons-why">
                    <button className="btn-why learn-more" onClick={redirectabout}>Learn More About Us</button>
                    <button className="btn-why contact-us" onClick={redirectcontact}>Contact Us</button>
                </div>
            </div>
        </div>
    )
}

export default Whyus
