import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import Topline from '../topline/Topline'


const Layout = ({ children }) => {
  return (
    <div>
      <Topline/>
      <Navbar />
      <div className="content">
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default Layout
